import React, { useMemo, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, Alert, AlertTitle } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import MainCard from 'ui-component/cards/MainCard';
import Header from './Header';
import Sidebar from './Sidebar';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { setAccountData, setClinic } from 'store/slices/account';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';
import JWTContext from 'contexts/JWTContext';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// eslint-disable-next-line react/prop-types
function ErrorFallback({ error }) {
    return (
        <MainCard title="Main Layout Crashed - refresh page required">
            <Alert severity="error">
                <AlertTitle>
                    Main layout app crashed: please submit a bug report with the url, actions you took to get here and a screenshot
                </AlertTitle>
                {/* eslint-disable-next-line react/prop-types */}
                {error?.message}
                {/* eslint-disable-next-line react/prop-types */}
                {error?.stack}
            </Alert>
        </MainCard>
    );
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const jwtContext = useContext(JWTContext);
    const { clinicId } = useParams();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    React.useEffect(() => {
        dispatch(
            setAccountData(
                jwtContext.email,
                jwtContext.userId,
                jwtContext.clinicGrants,
                jwtContext.organizationMeta,
                jwtContext.userMetadata
            )
        );
        if (clinicId && jwtContext.clinicGrants && jwtContext.clinicGrants[clinicId]) {
            dispatch(setClinic(clinicId));
        } else {
            /*
            when you press logout this will dispatch as undefined because selectedClinic is not part of the default state
            compontents listening on this will get a render for undefined as the selected clinic
            This should get moved to a context and not load certain compontents without this useContext
            */
            dispatch(setClinic(Object.keys(jwtContext.clinicGrants)[0]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwtContext]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={drawerOpen}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {/* breadcrumb */}
                    {container && (
                        <Container maxWidth="lg">
                            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
                            <Outlet />
                        </Container>
                    )}
                    {!container && (
                        <>
                            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
                            <Outlet />
                        </>
                    )}
                </ErrorBoundary>
            </Main>
        </Box>
    );
};

export default MainLayout;
