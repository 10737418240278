import axios from 'utils/axios';
import moment from 'moment';

import { mapValues, keyBy, map, cloneDeep, omitBy } from 'lodash';

/*
['mon', 'tue', 'wed'] --> {mon: true, tue: true, wed: true}
*/
function keyListToObject(input) {
    return mapValues(
        keyBy(input, (v) => v),
        () => true
    );
}

function unKeyObjectToList(input) {
    return map(
        omitBy(input, (v) => !v),
        (v, k) => k
    );
}

export async function getContactInformation(patientId) {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/contact-information`);

    if (!response.data.contactInformation.contactDays) {
        response.data.contactInformation.contactDays = {};
    }

    if (!response.data.contactInformation.contactTimes) {
        response.data.contactInformation.contactTimes = {};
    }

    response.data.contactInformation.contactDays = unKeyObjectToList(response.data.contactInformation.contactDays);
    response.data.contactInformation.contactTimes = unKeyObjectToList(response.data.contactInformation.contactTimes);

    return response;
}

export async function postContactInformation(patientId, data) {
    try {
        const transformedData = cloneDeep(data);
        transformedData.contactDays = keyListToObject(transformedData.contactDays);
        transformedData.contactTimes = keyListToObject(transformedData.contactTimes);
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/contact-information`,
            transformedData
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getPatientDetails(patientId) {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/details`);

    return response;
}

export async function postPatientDetails(patientId, data) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/details`, data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postAllergiesMedications(patientId, data) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/allergy-medication`, data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getAllergiesMedications(patientId) {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/allergy-medication`);

    return response;
}

function calculateCareSessionTime(careSessionStartTime, pausedStartTime, usingManual, manualCareTimeMinutes) {
    if (usingManual) {
        return Math.round(manualCareTimeMinutes * 60);
    }

    const now = new Date().getTime();
    if (pausedStartTime) {
        const pauseDuration = now - pausedStartTime;
        const fixedStartTime = careSessionStartTime + pauseDuration;
        return Math.round((now - fixedStartTime) / 1000);
    }

    return Math.round((now - careSessionStartTime) / 1000);
}

export async function postCareSession(patientId, formData, careSessionStartTime, pausedStartTime) {
    try {
        const postData = {
            careType: formData.careType,
            usingManualTimeEntry: formData.usingManualTimeEntry,
            durationSeconds: calculateCareSessionTime(
                careSessionStartTime,
                pausedStartTime,
                formData.usingManualTimeEntry,
                formData.manualCareTimeMinutes
            ),
            performedOn: formData.carePerformedAtTime,
            performedBy: formData.carePerformedBy,
            careNote: formData.careNote,
            complexCare: formData.complexCare,
            interactedWithPatient: formData.interactedWithPatient,
            interventions: formData.interventions,
            assessments: formData.assessments
        };
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-session`, postData);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postUpdateCareSession(patientId, careSessionId, formData) {
    try {
        const postData = {
            careType: formData.careType,
            performedOn: formData.carePerformedAtTime,
            performedBy: formData.carePerformedBy,
            careNote: formData.careNote,
            complexCare: formData.complexCare,
            interactedWithPatient: formData.interactedWithPatient,
            interventions: formData.interventions
        };
        if (formData.manualCareTimeMinutes || formData.manualCareTimeMinutes === 0) {
            postData.durationSeconds = Math.round(formData.manualCareTimeMinutes * 60);
        }
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-session/${careSessionId}`,
            postData
        );

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function listCareSessions(patientId, params) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-sessions`, {
            params
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function postNewPatient(formData) {
    try {
        const response = await axios.post('/api/org-id/{orgId}/clinic-id/{clinicId}/patient/new', formData);

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function searchPatientsWithTeamsMetadata(search) {
    try {
        const response = await axios.get('/api/org-id/{orgId}/clinic-id/{clinicId}/patient/search', {
            params: {
                q: search,
                withTeamsMetadata: true
            }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function bulkPatientEdit(data) {
    try {
        const response = await axios.post('/api/org-id/{orgId}/clinic-id/{clinicId}/patient/bulk-edit', data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function listPatients() {
    try {
        const fromTS = moment().startOf('month').valueOf();
        const toTS = moment().endOf('month').valueOf();
        const response = await axios.get('/api/org-id/{orgId}/clinic-id/{clinicId}/patient/list-with-care-times', {
            params: {
                fromTS,
                toTS
            }
        });
        response.data.patients.forEach((patient) => {
            patient.isEnrolled = patient.ccmEnrollment || patient.rpmEnrollment || patient.bhiEnrollment;
            patient.enrollments = [];
            if (patient.ccmEnrollment) {
                patient.enrollments.push('CCM');
            }
            if (patient.rpmEnrollment) {
                patient.enrollments.push('RPM');
            }
            if (patient.bhiEnrollment) {
                patient.enrollments.push('BHI');
            }
            patient.enrollmentCount = patient.ccmEnrollment + patient.rpmEnrollment + patient.bhiEnrollment;
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getMTDCareTime(patientId) {
    try {
        const fromTS = moment().startOf('month').valueOf();
        const toTS = moment().endOf('month').valueOf();
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/care-time`, {
            params: {
                fromTS,
                toTS
            }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getTeamsNextTaskAssignee(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/team/next-task-assignee`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
