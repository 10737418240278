// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import * as taskAPI from 'api/task';
import * as alertSnackbar from 'utils/snackbar-wrapper';

// max size allowed also defined in backend
const TASK_PAGE_SIZE = 50;

const initialState = {
    list: {
        error: false,
        loaded: false,
        needsRefresh: false,
        data: [],
        taskCount: 1,
        pageSize: TASK_PAGE_SIZE
    },
    /*
    Upon dispatching creates the template key with the default values marked unloaded until the data is fully loaded 
    key: taskId
    value: {
        error: false,
        loaded: false,
        data: [comments...]
    }
    each comment is an object containing:
    - id: comment id
    - taskId: id of task comment belongs to
    - comment: comment text
    - createdAt: date comment was created
    - createdByProviderId: id of provider who created comment
    - createdByProviderFirstName: first name of provider who created comment
    - createdByProviderLastName: last name of provider who created comment
    */
    commentsByTaskId: {},
    /* 
    data on a single task used outside of task list 
    not all properties are returned here
    */
    embeddedTaskByTaskId: {
        error: false,
        loaded: false,
        data: {}
    },

    // task stats for logged in user accessible and updated based on actions from any page in the sidebar
    taskStatsForLoggedInUser: {
        error: false,
        loaded: false,
        activeAssignedToMe: {
            clinic: 0,
            protocol: 0,
            total: 0
        }
    },

    taskStatsForHomepage: {
        error: false,
        loaded: false,
        countAssignedToMe: {
            clinic: 0,
            protocol: 0,
            total: 0
        },
        countAssignedToClinic: {
            clinic: 0,
            protocol: 0,
            total: 0
        },
        countAssignedToClinicProgrammatic: {},
        countByTeam: []
    }
};

const slice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setTaskListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setTaskList(state, action) {
            state.list.loaded = true;
            state.list.data = action.payload.list;
            state.list.taskCount = action.payload.taskCount;
        },
        setTaskListLoadingReset(state) {
            state.list = initialState.list;
        },
        resetState(state) {
            state.list = initialState.list;
            state.commentsByTaskId = initialState.commentsByTaskId;
        },
        // for other compontents to notify data has changed
        setNeedsRefresh(state) {
            state.list.needsRefresh = true;
        },
        // to disable that we have turned off refresh
        disableNeedsRefresh(state) {
            state.list.needsRefresh = false;
        },
        setLoadingTaskComments(state, action) {
            state.commentsByTaskId[action.payload] = {
                error: false,
                loaded: false,
                data: []
            };
        },
        setTaskCommentsError(state, action) {
            state.commentsByTaskId[action.payload] = {
                error: true,
                loaded: true,
                data: []
            };
        },
        setLoadedTaskComments(state, action) {
            state.commentsByTaskId[action.payload.taskId] = {
                error: false,
                loaded: true,
                data: action.payload.comments
            };
        },

        setEmbeddedTaskByTaskId(state, action) {
            state.embeddedTaskByTaskId.loaded = true;
            state.embeddedTaskByTaskId.error = false;
            state.embeddedTaskByTaskId.data = action.payload;
        },
        setEmbeddedTaskByTaskIdError(state, action) {
            state.embeddedTaskByTaskId.loaded = true;
            state.embeddedTaskByTaskId.error = action.payload;
            state.embeddedTaskByTaskId.data = {};
        },
        resetEmbeddedTaskByTaskId(state) {
            state.embeddedTaskByTaskId = initialState.embeddedTaskByTaskId;
        },

        setStatsForLoggedInUser(state, action) {
            state.taskStatsForLoggedInUser.loaded = true;
            state.taskStatsForLoggedInUser.error = false;
            state.taskStatsForLoggedInUser.activeAssignedToMe = action.payload.activeAssignedToMe;
        },
        setStatsForLoggedInUserError(state, action) {
            state.taskStatsForLoggedInUser.loaded = true;
            state.taskStatsForLoggedInUser.error = action.payload;
        },
        resetStatsForLoggedInUser(state) {
            state.taskStatsForLoggedInUser = initialState.taskStatsForLoggedInUser;
        },

        setStatsForHomepage(state, action) {
            state.taskStatsForHomepage.loaded = true;
            state.taskStatsForHomepage.error = false;
            state.taskStatsForHomepage.countAssignedToMe = action.payload.countAssignedToMe;
            state.taskStatsForHomepage.countAssignedToClinic = action.payload.countAssignedToClinic;
            state.taskStatsForHomepage.countAssignedToClinicProgrammatic = action.payload.countAssignedToClinicProgrammatic;
            state.taskStatsForHomepage.countByTeam = action.payload.countByTeam;
        },
        setStatsForHomepageError(state, action) {
            state.taskStatsForHomepage.loaded = true;
            state.taskStatsForHomepage.error = action.payload;
        },
        resetStatsForHomepage(state) {
            state.taskStatsForHomepage = initialState.taskStatsForHomepage;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetState() {
    dispatch(slice.actions.resetState());
}

export function resetEmbeddedTaskByTaskId() {
    dispatch(slice.actions.resetEmbeddedTaskByTaskId());
}

export function resetStatsForLoggedInUser() {
    dispatch(slice.actions.resetStatsForLoggedInUser());
}

export function resetStatsForHomepage() {
    dispatch(slice.actions.resetStatsForHomepage());
}

export function getTaskList(
    protocolsTask,
    {
        status,
        assignedTo,
        taskType,
        dueBefore,
        protocolStatus,
        protocolTemplate,
        mtdFilterField,
        mtdFilterOperation,
        mtdFilterValue,
        sortField,
        sortDirection,
        team
    },
    patientId,
    pageNumber
) {
    return async () => {
        const response = await taskAPI.getTaskList(
            protocolsTask,
            {
                status,
                assignedTo,
                taskType,
                dueBefore,
                protocolStatus,
                protocolTemplate,
                mtdFilterField,
                mtdFilterOperation,
                mtdFilterValue,
                sortField,
                sortDirection,
                team
            },
            patientId,
            pageNumber,
            TASK_PAGE_SIZE
        );
        if (response?.data?.list) {
            dispatch(slice.actions.setTaskList(response.data));
        } else {
            console.log(response);
            if (response?.statusCode === 400) {
                dispatch(alertSnackbar.warning(`Invalid filter for listing tasks`));
            } else {
                dispatch(alertSnackbar.error(`Failed to fetch task list: ${response.message}`));
            }
            dispatch(slice.actions.setTaskListError(response));
        }
    };
}

export function setLoadingList() {
    dispatch(slice.actions.setTaskListLoadingReset());
}

export function setNeedsRefresh() {
    dispatch(slice.actions.setNeedsRefresh());
}

export function disableNeedsRefresh() {
    dispatch(slice.actions.disableNeedsRefresh());
}

export function loadTaskComments(taskId) {
    return async () => {
        dispatch(slice.actions.setLoadingTaskComments(taskId));
        const response = await taskAPI.getTaskComments(taskId);
        if (response?.data?.comments) {
            dispatch(slice.actions.setLoadedTaskComments({ taskId, comments: response.data.comments }));
        } else {
            dispatch(alertSnackbar.error(`Failed to fetch task comments: ${response.message}`));
            dispatch(slice.actions.setTaskCommentsError(taskId));
        }
    };
}

// load an already loaded comments without setting initial loading state
export function reloadTaskComments(taskId) {
    return async () => {
        const response = await taskAPI.getTaskComments(taskId);
        if (response?.data?.comments) {
            dispatch(slice.actions.setLoadedTaskComments({ taskId, comments: response.data.comments }));
        } else {
            dispatch(alertSnackbar.error(`Failed to fetch task comments: ${response.message}`));
            dispatch(slice.actions.setTaskCommentsError(taskId));
        }
    };
}

export function loadEmbeddedTaskById(taskId) {
    return async () => {
        try {
            const response = await taskAPI.getTaskById(taskId);
            dispatch(slice.actions.setEmbeddedTaskByTaskId(response.data.task));
        } catch (error) {
            dispatch(slice.actions.setEmbeddedTaskByTaskIdError(error));
        }
    };
}

export function getActiveTaskStatsForLoggedInUser(clinicId) {
    return async () => {
        const response = await taskAPI.getActiveTaskStatsForLoggedInUser(clinicId);
        if (response.status === 200) {
            dispatch(slice.actions.setStatsForLoggedInUser(response.data));
        } else {
            dispatch(slice.actions.setStatsForLoggedInUserError(response.message));
        }
    };
}

export function getTaskStatsForHomepage(clinicId) {
    return async () => {
        const response = await taskAPI.getTaskStatsForHomepage(clinicId);
        if (response.status === 200) {
            dispatch(slice.actions.setStatsForHomepage(response.data));
        } else {
            dispatch(slice.actions.setStatsForHomepageError(response.message));
        }
    };
}
