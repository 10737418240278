import axios from 'utils/axios';

import { getTimezoneCode } from 'utils/timezone';

export async function getProviderTitles() {
    const response = await axios.get(`/api/org-id/{orgId}/provider-titles`);
    return response;
}

export async function getPrimaryClinicianList() {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/provider/list`, {
        params: {
            type: 'primaryClinician'
        }
    });
    return {
        data: {
            primaryClinicianList: response.data.providers
        }
    };
}

/**
 *
 * @param {Number} clinicId - Clinic.id
 * @param {Boolean} filterClinicManagerOrHigher - whether to apply a filter on role for clinic manager or higher
 * @returns
 */
export async function getClinicMembersList(clinicId, filterClinicManagerOrHigher) {
    try {
        const params = {};
        if (filterClinicManagerOrHigher) {
            params.namedRoleFilter = 'CLINIC_MANAGER_OR_HIGHER';
        }
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/provider/list-clinic-members`, {
            params
        });
        return {
            data: {
                providers: response.data.providers
            }
        };
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getSpecialistList() {
    const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/provider/list`, {
        params: {
            type: 'specialist'
        }
    });
    return {
        data: {
            specialistList: response.data.providers
        }
    };
}

export async function getProviderCareStatsForLoggedInUser() {
    const tzCode = getTimezoneCode();
    try {
        const response = await axios.get(`/api/org-id/{orgId}/provider/care-stats/me`, {
            params: {
                tz: tzCode
            }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getCustomRangeProviderCareStatsForLoggedInUser(fromTS, toTS) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/provider/care-stats/custom-time-range`, {
            params: {
                fromTS,
                toTS
            }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
