import axios from 'utils/axios';

export async function createTeam(data) {
    try {
        const response = await axios.post('/api/org-id/{orgId}/team/new', data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function updateTeam(teamId, data) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/team/${teamId}/edit`, data);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function deleteTeam(teamId) {
    try {
        const response = await axios.delete(`/api/org-id/{orgId}/team/${teamId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getTeamsList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/team/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getPatientAssignableTeams() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient-assignable-teams`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
