import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as teamsAPI from 'api/teams';

const initialState = {
    patientAssignableTeams: {
        error: false,
        loaded: false,
        data: []
    },
    list: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeamsListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setTeamsList(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetTeamsList(state) {
            state.list = initialState.list;
        },
        setPatientAssignableListError(state) {
            state.patientAssignableTeams.error = true;
            state.patientAssignableTeams.loaded = true;
        },
        setPatientAssignableList(state, action) {
            state.patientAssignableTeams.error = false;
            state.patientAssignableTeams.loaded = true;
            state.patientAssignableTeams.data = action.payload;
        },
        resetPatientAssignableList(state) {
            state.patientAssignableTeams = initialState.patientAssignableTeams;
        }
    }
});

export default slice.reducer;

export function resetTeamsList() {
    dispatch(slice.actions.resetTeamsList());
}

export function getTeamsList() {
    return async () => {
        try {
            const response = await teamsAPI.getTeamsList();
            dispatch(slice.actions.setTeamsList(response.data.teams));
        } catch (error) {
            dispatch(slice.actions.setTeamsListError(error));
        }
    };
}

export function resetPatientAssignableList() {
    dispatch(slice.actions.resetPatientAssignableList());
}

export function getPatientAssignableTeams() {
    return async () => {
        try {
            const response = await teamsAPI.getPatientAssignableTeams();
            dispatch(slice.actions.setPatientAssignableList(response.data.teams));
        } catch (error) {
            dispatch(slice.actions.setPatientAssignableListError(error));
        }
    };
}
