// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import * as patientAPI from 'api/patient';
import * as treatmentPlanAPI from 'api/treatmentPlan';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    patientDetails: {
        loaded: false,
        error: false,
        data: {
            assignedTeam: '', // integer id, empty string if not assigned
            firstName: '',
            middleName: '',
            lastName: '',
            mrn: '',
            medicareId: '',
            gender: '',
            preferredName: '',
            phoneNumber: '',
            nonHealthNote: '',
            dateOfBirth: ''
        }
    },
    teamsNextTaskAssignee: {
        loaded: false,
        error: false,
        data: {}
    },
    openTaskCount: {
        clinic: 0,
        protocol: 0,
        total: 0
    },
    mtdCareTime: {
        ccm: 0,
        bhi: 0,
        rpm: 0,
        ccmComplexCareSession: false,
        ccmInteractedWithPatientSession: false,
        loaded: false
    },
    emergencyContacts: {
        loaded: false,
        data: []
    },
    contactInformation: {
        loaded: false,
        data: {
            phoneNumber: '',
            address: '',
            apt: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            additionalNumbers: [],
            contactDays: [],
            contactTimes: []
        }
    },
    allergiesMedications: {
        loaded: false,
        data: {
            allergies: '',
            medications: ''
        }
    },
    latestFinalizedCarePlanAssessments: {
        loaded: false,
        error: false,
        latestFinalizedCarePlanId: null,
        assessments: [],
        definitions: []
    }
};

const slice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        setPatientDetails(state, action) {
            state.patientDetails.error = false;
            state.patientDetails.loaded = true;
            state.patientDetails.data = action.payload;
        },

        setPatientDetailsError(state) {
            state.patientDetails.error = true;
            state.patientDetails.loaded = true;
        },

        setPatientOpenTaskCount(state, action) {
            state.openTaskCount = action.payload;
        },

        setEmergencyContacts(state, action) {
            state.emergencyContacts.loaded = true;
            state.emergencyContacts.data = action.payload;
        },

        setContactInformation(state, action) {
            state.contactInformation.loaded = true;
            state.contactInformation.data = action.payload;
        },

        setAllergiesMedications(state, action) {
            state.allergiesMedications.loaded = true;
            state.allergiesMedications.data = action.payload;
        },

        setMTDCareTime(state, action) {
            state.mtdCareTime.ccm = action.payload.ccm;
            state.mtdCareTime.rpm = action.payload.rpm;
            state.mtdCareTime.bhi = action.payload.bhi;
            state.mtdCareTime.ccmComplexCareSession = action.payload.ccmComplexCareSession;
            state.mtdCareTime.ccmInteractedWithPatientSession = action.payload.ccmInteractedWithPatientSession;
            state.mtdCareTime.loaded = true;
        },

        setLatestFinalizedCarePlanAssessments(state, action) {
            state.latestFinalizedCarePlanAssessments.loaded = true;
            state.latestFinalizedCarePlanAssessments.assessments = action.payload.assessments;
            state.latestFinalizedCarePlanAssessments.definitions = action.payload.definitions;
            state.latestFinalizedCarePlanAssessments.latestFinalizedCarePlanId = action.payload.latestFinalizedCarePlanId;
        },
        setLatestFinalizedCarePlanAssessmentsError(state) {
            state.latestFinalizedCarePlanAssessments.loaded = true;
            state.latestFinalizedCarePlanAssessments.error = true;
        },

        setTeamsNextTaskAssignee(state, action) {
            state.teamsNextTaskAssignee.error = false;
            state.teamsNextTaskAssignee.loaded = true;
            state.teamsNextTaskAssignee.data = action.payload;
        },

        setTeamsNextTaskAssigneeError(state) {
            state.teamsNextTaskAssignee.error = true;
            state.teamsNextTaskAssignee.loaded = true;
        },

        resetState(state) {
            state.error = initialState.error;
            state.patientDetails = initialState.patientDetails;
            state.emergencyContacts = initialState.emergencyContacts;
            state.contactInformation = initialState.contactInformation;
            state.allergiesMedications = initialState.allergiesMedications;
            state.openTaskCount = initialState.openTaskCount;
            state.mtdCareTime = initialState.mtdCareTime;
            state.latestFinalizedCarePlanAssessments = initialState.latestFinalizedCarePlanAssessments;
            state.teamsNextTaskAssignee = initialState.teamsNextTaskAssignee;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// on patient change clear out state
export function resetState() {
    dispatch(slice.actions.resetState());
}

export function getPatientDetails(patientId) {
    return async () => {
        try {
            const response = await patientAPI.getPatientDetails(patientId);
            dispatch(slice.actions.setPatientDetails(response.data.patientDetails));
            dispatch(slice.actions.setPatientOpenTaskCount(response.data.openTaskCount));
        } catch (error) {
            dispatch(slice.actions.setPatientDetailsError());
        }
    };
}

export function getEmergencyContacts(patientId) {
    return async () => {
        try {
            const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/emergency-contact`);

            dispatch(slice.actions.setEmergencyContacts(response.data.emergencyContacts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postEmergencyContacts(patientId, emergencyContactData) {
    return async () => {
        try {
            await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/emergency-contact`, emergencyContactData);
            // dispatch(slice.actions.setEmergencyContacts(emergencyContactData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getContactInformation(patientId) {
    return async () => {
        try {
            const response = await patientAPI.getContactInformation(patientId);

            dispatch(slice.actions.setContactInformation(response.data.contactInformation));
        } catch (error) {
            console.log('ERROR', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllergiesMedications(patientId) {
    return async () => {
        try {
            const response = await patientAPI.getAllergiesMedications(patientId);

            dispatch(slice.actions.setAllergiesMedications(response.data.allergiesMedications));
        } catch (error) {
            console.log('ERROR', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMTDCareTime(patientId) {
    return async () => {
        try {
            const response = await patientAPI.getMTDCareTime(patientId);
            dispatch(slice.actions.setMTDCareTime(response.data.careTime));
        } catch (error) {
            // error state for MTD care time widget is to not mark as loaded
        }
    };
}

export function getLatestFinalizedCarePlanAssessments(patientId) {
    return async () => {
        try {
            const response = await treatmentPlanAPI.getActiveAssessments(patientId);
            dispatch(slice.actions.setLatestFinalizedCarePlanAssessments(response.data));
        } catch (error) {
            dispatch(slice.actions.setLatestFinalizedCarePlanAssessmentsError(error));
        }
    };
}

export function getTeamsNextTaskAssignee(patientId) {
    return async () => {
        try {
            const response = await patientAPI.getTeamsNextTaskAssignee(patientId);
            dispatch(slice.actions.setTeamsNextTaskAssignee(response.data.assignee));
        } catch (error) {
            dispatch(slice.actions.setTeamsNextTaskAssigneeError(error));
        }
    };
}
